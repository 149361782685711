<template>
  <div class="public-body">
    <div class="public-body__block  public-body__block--decor">
      <img src="@/assets/img/mkp-logo.svg" alt="logo" />
    </div>
    <div class="public-body__block">
      <div class="public-body__pic">
        <img src="@/assets/img/public/error404-icon.svg" alt="">
      </div>
      <div class="public-body__headline">К сожалению, страница не найдена</div>
      <div class="public-body__text">Похоже, мы не можем найти нужную вам страницу</div>
      <div class="public-body__button">
        <a href="/" class="button">На главную</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>
